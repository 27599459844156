import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Menu" />
    <h1>Menu</h1>
    <h3>Food</h3>
    <ul>
      <li><a href="/">Panizza</a></li>
    </ul>
    <h3>Drink</h3>
    <ul>
      <li><a href="https://en.wikipedia.org/wiki/Water">Water</a></li>
    </ul>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
